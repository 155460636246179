@use '../abstracts/breakpoints';

.terms-privacy-dialog {
  width: 80vw;
  height: 90vh;

  @media (max-width: breakpoints.$max-width--mobile) {
    max-width: 100vw !important;
    width: 100vw;
    height: 95vh;

    mat-dialog-container {
      padding: 1rem;
    }
  }
}

.image-viewer-overlay-panel {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
