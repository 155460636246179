button {
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

body {
  height: 100%;
}
