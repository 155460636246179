@use '../abstracts/variables';
.mdc-snackbar:not(.success-toast, .error-toast) {
  .mdc-snackbar__surface {
    --mdc-snackbar-supporting-text-color: #{variables.$sympliact-tealish};
    --mat-snack-bar-button-color: #{variables.$sympliact-tealish};
  }
}

.success-toast {
  --mdc-snackbar-container-color: #{variables.$sympliact-success};
  --mdc-snackbar-supporting-text-color: #{variables.$white};
}

.error-toast {
  --mdc-snackbar-container-color: #{variables.$sympliact-red};
  --mdc-snackbar-supporting-text-color: #{variables.$white};
}
