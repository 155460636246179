/* You can add global styles to this file, and also import other style files */
@use 'abstracts/variables';

@use 'base/typography';
@use 'base/reset';
@use 'base/normalize';

@use 'themes/material';

@use 'components/dialog';
@use 'components/snackbar';
@use 'components/material/mat-menu';
@use 'components/material/mat-button';
@use 'components/material/mat-tab';
.mat-tooltip {
  font-size: 0.8rem !important;
  background: variables.$sympliact-darker-grey;
  color: variables.$white;
}

.mention {
  background-color: variables.$mention-background;
  color: black;
  padding: 0 0.5rem;
  border-radius: 10px;
}

.hide-subscript {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}
