@use '../../abstracts/variables';

.care-document-menu {
  .care-document {
    padding: 0.25rem;
    display: flex;
    justify-content: space-between;

    &__details {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
      font-size: 0.8rem;
    }
  }
}
