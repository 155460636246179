$white: #fff;
$red: #ff0000;
$black: #000;

$sympliact-grey-brown: #4a4a4a;
$sympliact-light-grey: #9b9b9b;
$sympliact-darker-grey: #757575;
$sympliact-light-blue: #7eeaf3;
$sympliact-red: #b3261e;
$sympliact-tealish: #39b3c7;
$sympliact-success: #386a20;

/* Theme Variables */
$primary--dark: #212427;
$primary--light: $sympliact-tealish;

$background-color: #f4f8fa;

/* Calc Variables */
$horizontal-nav-height: 50px;
$logo-bar-height: 65px;

/* Component based */
$field-title: #c4c4c4;
$mention-background: #579dff;
$hover-background: rgba(0, 218, 196, 0.2);

/* Font Family */
$Roboto-Light: 'Roboto Light';
$Roboto-Light-Italic: 'Roboto Light Italic';
$Roboto: 'Roboto';
$Roboto-Medium: 'Roboto Medium';
$Roboto-Bold: 'Roboto Bold';

/* Text Grays */
$text-gray: rgb(107, 114, 128);
